import React, { useRef, useEffect } from "react";
import "./App.css";
import * as facemesh from "@tensorflow-models/face-landmarks-detection";
import Webcam from "react-webcam";
import '@tensorflow/tfjs-backend-webgl';

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  //  Load posenet
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runFacemesh = async () => {
    const net = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh);
    setInterval(() => {
      detect(net);
    }, 10);
  };

  const detect = async (net) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;

      // const videoWidth = webcamRef.current.video.videoWidth;
      // const videoHeight = webcamRef.current.video.videoHeight;

      // // Set video width
      // webcamRef.current.video.width = videoWidth;
      // webcamRef.current.video.height = videoHeight;

      // // Set canvas width
      // containerRef.current.width = videoWidth;
      // containerRef.current.height = videoHeight;

      // Make Detections
      const face = await net.estimateFaces({ input: video });

      if (face[0]) {
        const noseXAxis = face[0].annotations.noseTip[0][0] 
        const noseYAxis = face[0].annotations.noseTip[0][1]*2
        requestAnimationFrame(() => {
          containerRef.current.style.transform = `translate(${noseXAxis}px, ${noseYAxis}px)`
          containerRef.current.style.visibility = "visible"
        })  
    }
      }
  };

  useEffect(() => {
    runFacemesh()
    
  }, [runFacemesh]);

 


  return (
    <div className="App">
        <Webcam
          ref={webcamRef}
          className="webCam"
        />
        <canvas
          ref={canvasRef}
          className="canvas"
        />
        <div  className="container" >
          <div className="logo-container" ref={containerRef}>
            <img className="logo-snipes" alt="snipes-logo" src="/snipes-gray.png" />
          </div>
        </div>

    </div>
  );
}

export default App;
